<template>
  <div class="applyActivity">
    <div class="tab">
      <div
        v-for="(item, index) in tabList"
        :key="index"
        :class="['item', { active: requestData.activityCategory == item.key }]"
        @click="selectMem(item, index)"
      >
        <span
          >{{ item.label }}
          <span
            v-if="requestData.activityCategory == item.key"
            class="round"
          ></span
        ></span>
      </div>
    </div>
    <v-list
      :finished="finished"
      :immediateCheck="false"
      @onLoad="onLoad"
      ref="list"
      :finishedText="finishedText"
      :commonOperaParams="commonOperaParams"
    >
      <div v-for="(item, index) in listData" :key="index">
        <div class="activityItem" @click="toDetail(item)">
          <div class="activityItem-t">
            <img :src="item.activityPicture" alt="" />
          </div>
          <div class="activityItem-c">
            <div class="title">
              <div class="title-l" v-if="item.isApplied == 1">
                <img src="./img/signUp.png" alt="" /><span>已报名</span>
              </div>
              <div class="title-r">{{ item.activityName }}</div>
              <div class="status-red" v-if="item.activityCategory == 1">
                热门活动
              </div>
              <div class="status-blue" v-else-if="item.activityCategory == 20">
                时间银行
              </div>
              <div class="status-blue" v-else-if="item.activityCategory == 19">
                幸福学堂
              </div>
            </div>
            <div class="address">{{ item.activityAddress }}</div>
            <p class="p-apply">报名开始时间：{{ item.applyStartDate }}</p>
            <p class="p-activity">活动开始时间：{{ item.startDate }}</p>
          </div>
          <div class="activityItem-b">
            <div class="operate">
              <div class="operate-l">
                <div class="people">
                  <div class="people-l">
                    <img src="./img/newPeople.png" alt="" />
                  </div>
                  <div class="people-r">{{ item.applyUserCount || 0 }}</div>
                </div>
                <div class="comment">
                  <div class="comment-l">
                    <img src="./img/comment.png" alt="" />
                  </div>
                  <div class="comment-r">{{ item.commentCount || 0 }}</div>
                </div>
              </div>
              <div class="operate-r">
                <div
                  :class="[
                    'btn',
                    {
                      applying: item.activityStatus == 2,
                      going: item.activityStatus == 4,
                      back: item.hasFeedback && item.hasFeedback == 1,
                    },
                  ]"
                >
                  <span>{{
                    getOption(item.activityStatus, item.hasFeedback, item)
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-list>
    <v-dialog
      class="dialog"
      v-model="showDialog"
      :showConfirmButton="false"
      :showCancelButton="false"
      :closeOnClickOverlay="true"
    >
      <!-- <template v-if="!errorMsg"> -->
      <p class="dialog-content">您还未申请志愿者</p>
      <div class="dialog-btn" @click="apply">
        <div class="btn">立即申请</div>
      </div>
      <!-- </template> -->
      <!-- <template v-else>
        <p class="dialog-content">{{ errorMsg }}</p>
        <div class="dialog-btn" @click="apply(0)">
          <div class="btn close">关闭</div>
        </div>
      </template> -->
    </v-dialog>
    <img src="./img/toMy.png" class="toMy" @click="toMy" alt="" />
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import {
  getActivityListUrl,
  volunteerUrl,
  ifCommitmentLetterUrl,
} from "./api.js";
import { getOption } from "@/utils/utils.js";
import { activityStatus } from "./map.js";
import { gloabalCount, toRegister } from "@/utils/common";
export default {
  name: "applyActivity",
  data() {
    return {
      finishedText: "没有更多了",
      activityStatus,
      finished: false,
      commonOperaParams: {
        showMy: false,
        isCommonOpera: false,
      },
      requestData: {
        curPage: 1,
        pageSize: 10,
      },
      tabList: [
        { label: "全部", key: null },
        { label: "热门活动", key: "1" },
        { label: "幸福学堂", key: "19" },
        { label: "时间银行", key: "20" },
      ],
      type: "热门活动",
      listData: [],

      /* v-dialog */
      showDialog: false,
      errorMsg: "",
    };
  },
  mounted() {
    gloabalCount("", 2, 1);
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    houseId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  watch: {
    userId: {
      handler(newValue, oldValue) {
        if (newValue) {
          this.onLoad();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    toMy() {
      this.$router.push({
        name: "myEnroll",
      });
    },
    async selectMem(item, index) {
      this.requestData.activityCategory = item.key;
      this.type = item.label;
      this.finished = false;
      this.finishedText = "没有更多了";
      this.$refs.list._data.loading = true;
      this.requestData.curPage = 1;
      this.listData = [];
      // if (item.key === "20") {
      //   await this.getVolunteer();
      // }
      this.getActivityList();
    },
    async getVolunteer() {
      this.errorMsg = "";
      let params = {
        userId: this.userId,
      };
      let res = await this.$axios.post(`${volunteerUrl}`, null, {
        params: params,
      });
      if (res.code === 200 && res.success) {
        return;
      }
      this.errorMsg = res.msg;
      /** start 时间银行特殊处理 */
      if (this.requestData.activityCategory === "20" && this.errorMsg) {
        this.showDialog = true;
        return;
      }
      /** end */
    },
    // 申请或者关闭
    apply() {
      // if (n === 0) {
      // this.showDialog = false;
      //   return;
      // }
      this.$router.push({
        name: "volunteerApply",
        query: {
          userId: this.userId,
        },
      });
    },
    getOption(status, hasFeedback, item) {
      if (hasFeedback && hasFeedback == 1) {
        return "活动回顾";
      }
      if (item.applyUserCount >= item.limitCount && item.limitCount != 0) {
        return "报名已满";
      }
      if (getOption(status, activityStatus, "key")["value"]) {
        return getOption(status, activityStatus, "key")["value"];
      } else {
        return "";
      }
    },
    getActivityList() {
      let params = {
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
        userId: this.userId,
        houseId: this.houseId || this.communityId || undefined,
        tenantId: this.tenantId,
        activityCategory: this.requestData.activityCategory,
      };
      this.$axios
        .get(`${getActivityListUrl}`, { params: params })
        .then((res) => {
          console.log(res, 333);
          if (res.code === 200) {
            if (res.data.pages <= res.data.current) {
              this.finished = true;
            } else {
              this.$refs.list._data.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.finishedText = "";
              return;
            }
            res.data.records.forEach((item) => {
              if (item.activityPicture) {
                item.activityPicture = this.$handleImg(
                  686,
                  238,
                  item.activityPicture
                );
              }
              this.listData.push(item);
            });
          } else {
          }
        });
    },
    onLoad() {
      this.getActivityList();
    },
    async toDetail(item) {
      /** start 时间银行特殊处理 */
      // if (item.activityCategory === 20) {
      //   let result = await toRegister(
      //     this.$route.path,
      //     this.$route.query,
      //     "热门活动报名"
      //   );
      //   if (!result) {
      //     return;
      //   }
      //   if (item.activityCategory === 20 && this.errorMsg) {
      //     this.showDialog = true;
      //     return;
      //   }
      //   if (item.activityCategory === 20 && !this.errorMsg) {
      //     const result = await this.ifCommitmentLetter(item.activityId);
      //     if (!result) {
      //       this.$router.push({
      //         name: "undertaking",
      //         query: {
      //           activityId: item.activityId,
      //         },
      //         replace: true,
      //       });
      //       return;
      //     }
      //   }
      // }
      /** end */
      let params = {
        activityId: item.activityId,
        type: this.type,
      };
      // wx.miniProgram.navigateTo({
      //   url: `/xubPages/activityDetail/index?params=${JSON.stringify(params)}`,
      // });
      this.$router.push({
        name: "activitySquareDetail",
        query: { activityId: item.activityId },
      });
    },
    // 判断用户是否已签署承诺书
    async ifCommitmentLetter(activityId) {
      const res = await this.$axios.post(ifCommitmentLetterUrl, null, {
        params: {
          activityId,
          userId: this.userId,
        },
      });
      return res.data;
    },
  },
};
</script>
<style lang="less" scoped>
.applyActivity {
  min-height: 100%;
  background: #fafafaff;
  padding: 95px 32px 20px;
  box-sizing: border-box;
  .toMy {
    width: 128px;
    position: fixed;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
  }
  .dialog {
    font-size: 34px;
    font-weight: bold;
    color: #000000d9;
    p.dialog-content {
      text-align: center;
      padding: 56px 0 92px 0;
    }
    div.dialog-btn {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 56px;
      .btn {
        width: 440px;
        height: 66px;
        text-align: center;
        line-height: 66px;
        color: #ffffff;
        font-weight: bold;
        font-size: 30px;
        background: linear-gradient(180deg, #b0e2dd 0%, #54a2ac 100%);
        border-radius: 10px;
        &.close {
          background: linear-gradient(180deg, #ededed 0%, #d8d8d8 100%);
          color: #a7a7a7ff;
        }
      }
    }
  }
}
.tab {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 96px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  padding: 0 32px;
  box-sizing: border-box;
  z-index: 2;
  // padding: 32px 0 0;
  // color: #71a4ffff;
  color: #71a4ff;
  > div {
    // flex: 1;
    position: relative;
    height: 50px;
    display: flex;
    font-size: 32px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    line-height: 50px;
    span {
      vertical-align: middle;
      // line-height: 1;
      position: relative;
      .round {
        position: absolute;
        right: -15px;
        top: 0;
        display: block;
        height: 10px;
        width: 10px;
        background: #ffb92dff;
        border-radius: 50%;
      }
    }
  }
  .active {
    font-weight: bold;
    // color: #548a86;
    color: #367bf4;
  }
}
.activityItem {
  position: relative;
  // height: 604px;
  border-radius: 16px;
  background: #ffffff;
  box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
  margin-bottom: 32px;
  .activityItem-t {
    width: 100%;
    height: 280px;
    overflow: hidden;
    position: relative;
    img {
      border-radius: 16px 16px 0 0;
      width: 100%;
      //height: 100%;
    }
  }
  .activityItem-c {
    padding: 20px 24px 22px;
    box-sizing: border-box;
    .title {
      display: flex;
      align-items: center;
      .title-l {
        width: 102px;
        height: 32px;
        margin-right: 8px;
        position: relative;
        font-size: 0;
        img {
          width: 100%;
          height: 100%;
        }
        span {
          width: 100%;
          height: 100%;
          text-align: center;
          position: absolute;
          top: 0;
          left: 0;
          font-size: 20px;
          line-height: 32px;
          color: #fff;
          font-weight: bold;
        }
      }
      .title-r {
        width: 480px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 32px;
        font-weight: bold;
        color: #323334;
        line-height: 44px;
      }
      .status-red {
        width: 124px;
        height: 42px;
        background: rgba(224, 32, 32, 0.1);
        border-radius: 4px;
        border: 2px solid #e02020;
        font-size: 24px;
        color: #e02020;
        text-align: center;
        line-height: 42px;
      }
      .status-blue {
        width: 124px;
        height: 42px;
        background: rgba(42, 114, 113, 0.11);
        border-radius: 4px;
        border: 2px solid #2a7271;
        font-size: 24px;
        color: #2a7271;
        text-align: center;
        line-height: 42px;
      }
    }
    .address {
      font-size: 24px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
      line-height: 36px;
      margin: 8px 0 14px;
    }
    p {
      font-size: 24px;
      padding-left: 24px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      line-height: 36px;
      margin-bottom: 6px;
    }
    .p-apply,
    .p-activity {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        width: 12px;
        height: 12px;
        background: #1ebb70;
        border-radius: 50%;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }
    .p-activity {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        width: 12px;
        height: 12px;
        background: #3a69ed;
        border-radius: 50%;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }
  }
  .activityItem-b {
    padding: 18px 24px 18px;
    box-sizing: border-box;
    .operate {
      display: flex;
      // padding: 14px 0 0 0;
      box-sizing: border-box;
      justify-content: space-between;
      align-items: center;
      .operate-l,
      .operate-r {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .people {
        width: 160px;
      }
      .people,
      .comment {
        display: flex;
        align-items: center;
      }
      .people-l,
      .comment-l {
        width: 28px;
        height: 28px;
        font-size: 0;
        margin-right: 10px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .people-r,
      .comment-r {
        padding-top: 2px;
        line-height: 1;
        font-size: 28px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
      }
      .btn {
        position: absolute;
        bottom: 0;
        right: 0;
        box-sizing: border-box;
        height: 64px;
        // border: 4px solid #fe6f16;
        border-radius: 16px 0 16px 0;
        // color: #fe6f16;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 24px;
        min-width: 194px;
        background: linear-gradient(180deg, #ededed 0%, #d8d8d8 100%);
        color: #a7a7a7ff;
        box-shadow: 0px 4px 8px 0px rgba(225, 225, 225, 0.5);
        > span {
          font-size: 26px;
          font-weight: bold;
          line-height: 1;
        }
      }
      .btn.applying {
        background: linear-gradient(180deg, #b0e2dd 0%, #54a2ac 100%);
        color: #ffffff;
      }
      .btn.going {
        background: linear-gradient(180deg, #fdc76f 0%, #faae3e 100%);
        color: #ffffff;
      }
      .btn.back {
        background: linear-gradient(180deg, #fdc76f 0%, #faae3e 100%);
        color: #ffffff;
      }
    }
  }
}
</style>
